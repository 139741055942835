import {
    mapState,
    mapActions 
} from 'vuex'

export default {
    name : "DialogForm",
    props :{
        title : {
            type     : String,
            required : true
        },
        nameD : {
            type     : String,
            required : true
        },
        propWidth : {
            type     : String,
            default  : "50%"
        }
    },
    data : () => ({
        Cerrar : "cerrar"
    }), 
    computed : {
        ...mapState("DialogForm", ["dialogs"]),
       dialog(){
           return this.dialogs[this.nameD].status
       }
    },
    methods : {
        ...mapActions("DialogForm",["setNameDialog", "setDialog"]),
         /**
         * @description emite evento de cerrar
         */
        eventClose(){
            this.$emit('eventClose')
        },
        /**
         * @description emite evento de save
         */
        eventSave() { 
            this.$emit('eventSave')
        },
    },
    created(){        
        this.setNameDialog(this.nameD);
    }
}
